.App {
  background-color: #f5f5dc;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #d7d7c2;
  width: 100%;
  padding: 1rem;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-item {
  display: inline-block;
  text-decoration: none;
}

.nav-item img {
  width: auto;
  height: 50px;
  margin: 0 0.5rem;
  cursor: pointer;
}

  

.footer {
  background-color: #d7d7c2;
  width: 100%;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 2rem;
  margin: 0 0.5rem;
  color: #333;
  transition: color 0.3s;
}

.icon:hover {
  color: #999;
}
